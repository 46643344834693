import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as ai3PXZmDxyPkMeta } from "/vercel/path0/pages/ai.vue?macro=true";
import { default as blog_45pageEMVrO793y7Meta } from "/vercel/path0/pages/blog-page.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as coursesGHU0htcrbwMeta } from "/vercel/path0/pages/courses.vue?macro=true";
import { default as guidesNw1Sa9NM0xMeta } from "/vercel/path0/pages/guides.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ai",
    path: "/ai",
    component: () => import("/vercel/path0/pages/ai.vue").then(m => m.default || m)
  },
  {
    name: "blog-page",
    path: "/blog-page",
    component: () => import("/vercel/path0/pages/blog-page.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/vercel/path0/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/vercel/path0/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]